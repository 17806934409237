.content[data-v-06692ebc] {
  width: 100%;
  height: 100%;
}
.xxStyle[data-v-06692ebc] {
  display: flex;
  height: 40px;
  align-items: center;
  margin: 0 40px;
}
.w300[data-v-06692ebc] {
  width: 160px;
}
.w135[data-v-06692ebc] {
  width: 135px;
}
.w120[data-v-06692ebc] {
  width: 120px;
}
.w50[data-v-06692ebc] {
  width: 50px;
  margin-right: 10px;
}
.flex[data-v-06692ebc] {
  display: flex;
  margin-left: 20px;
}
.save[data-v-06692ebc] {
  margin-top: 80px;
  width: 700px;
}
.empty[data-v-06692ebc] {
  width: 600px;
  height: 400px;
}